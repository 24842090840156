import "@/assets/iconfont/iconfont.css";
import * as Echarts from "echarts";
import ElementUI from "element-ui";
import locale from "element-ui/lib/locale/lang/en";
import "element-ui/lib/theme-chalk/index.css";
import Vue from "vue";
import VueBus from "vue-bus";
import App from "./App.vue";
import router from "./router";

Vue.prototype.$Echarts = Echarts;
Vue.config.productionTip = false;
Vue.use(ElementUI, { locale });
Vue.use(VueBus);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");

router.beforeEach((to, from, next) => {
  if (to.fullPath.indexOf("/login") >= 0 || isLogin()) {
    return next();
  }
  console.log(to.fullPath);
  try {
    next({
      path: "/login",
      query: {
        redirect: to.fullPath,
      }, // 将跳转的路由path作为参数，登录成功后跳转到该路由
    });
  } catch (e) {
    console.log(e);
  }
});

function isLogin() {
  let ticket = localStorage.getItem("pstoken");
  return ticket != null;
}
