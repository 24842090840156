<template>
  <div class="header">
    <div class="header-left" @click="changeCollapse">
      <span v-if="collapse == false">
        <i class="el-icon-s-fold"></i>
      </span>
      <span v-else>
        <i class="el-icon-s-unfold"></i>
      </span>
    </div>
    Product Service Portal
    <div class="header-right">
      <el-popover
        placement="top-start"
        title="System Notification"
        width="200"
        trigger="hover"
        :content="message"
      >
        <span slot="reference"><i class="el-icon-message-solid"></i></span>
      </el-popover>
    </div>
  </div>
</template>
<script>
import bus from "../store/bus";
export default {
  name: "Header",
  data() {
    return {
      message: "No notification.",
      collapse: false,
    };
  },
  methods: {
    changeCollapse() {
      this.collapse = !this.collapse;
      bus.$emit("collapse", this.collapse);
    },
  },
};
</script>
<style scoped>
.header {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background: #15375a;
  font-size: 16px;
  color: white;
  /* font-weight: bold; */
}
.header-left {
  width: 40px;
  height: 40px;
  line-height: 40px;
  float: left;
}
.header-right {
  width: 40px;
  height: 40px;
  line-height: 40px;
  float: right;
}
</style>
