<template>
  <div>
    <el-container>
      <el-aside
        v-if="collapse == false"
        width="200px"
        v-bind:style="{ height: height + 'px' }"
      >
        <Menu style="height: 100%;"></Menu>
      </el-aside>
      <el-aside v-else width="100px" v-bind:style="{ height: height + 'px' }">
        <Menu style="height: 100%;"></Menu>
      </el-aside>
      <el-main v-bind:style="{ height: height + 'px' }">
        <!-- <Tabs></Tabs> -->
        <router-view />
      </el-main>
    </el-container>
  </div>
</template>
<script>
import Menu from "./Menu.vue";
// import Tabs from "./Tabs.vue";
import bus from "../store/bus";
export default {
  name: "Layout",
  components: {
    Menu,
    // Tabs,
  },
  data() {
    return {
      screenHeight: window.innerHeight,
      collapse: false,
      minHeight: 0,
      height: window.innerHeight - 68,
    };
  },
  mounted() {
    bus.$on("collapse", (collapse) => {
      this.collapse = collapse;
    });
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.screenHeight = window.innerHeight - 100;
    },
  },
};
</script>
<style scoped>
/* .el-aside{
    min-height: 1000px;
}
.el-main{
    min-height: 1000px;
} */
</style>
