<template>
  <el-menu
    style="text-align: left;"
    :default-active="defaultActive"
    :collapse="collapse"
    class="el-menu-vertical-demo"
    :unique-opened="true"
    router
    @select="handleMenuSelect"
  >
    <el-menu-item index="home">
      <i class="el-icon-s-home"></i>
      <span slot="title">Home</span>
    </el-menu-item>
    <el-menu-item index="request">
      <i class="el-icon-phone"></i>
      <span slot="title">Request</span>
    </el-menu-item>
    <el-menu-item index="dictionary">
      <i class="el-icon-cidiandictionary"></i>
      <span slot="title">Dictionary</span>
    </el-menu-item>
    <el-menu-item index="translate">
      <i class="el-icon-fanyi7"></i>
      <span slot="title">Translate</span>
    </el-menu-item>
    <el-menu-item index="transcode">
      <i class="el-icon-zhuanhuan1"></i>
      <span slot="title">Transcode</span>
    </el-menu-item>
    <el-menu-item index="POC">
      <i class="el-icon-yanshibofang"></i>
      <span slot="title">POC</span>
    </el-menu-item>
    <el-menu-item index="release">
      <i class="el-icon-chanpinfabu1"></i>
      <span slot="title">Release</span>
    </el-menu-item>
    <el-menu-item index="video">
      <i class="el-icon-video-camera-solid"></i>
      <span slot="title">Videos</span>
    </el-menu-item>
    <!-- <el-menu-item index="user">
      <i class="el-icon-user-solid"></i>
      <span slot="title">User</span>
    </el-menu-item>
    <el-menu-item index="customer">
      <i class="el-icon-wodekehu1"></i>
      <span slot="title">Customer</span>
    </el-menu-item> -->
    <!-- <el-menu-item index="SLA">
      <i class="el-icon-jiaoyixieyi"></i>
      <span slot="title">SLA</span>
    </el-menu-item> -->
    <el-menu-item index="report">
      <i class="el-icon-s-data"></i>
      <span slot="title">Report</span>
    </el-menu-item>
    <!-- <el-menu-item index="transcode">
      <i class="el-icon-zhuanhuan1"></i>
      <span slot="title">Transcode</span>
    </el-menu-item>
    <el-menu-item index="translation">
      <i class="el-icon-fanyi1"></i>
      <span slot="title">Translation</span>
    </el-menu-item> -->
    <!-- <el-menu-item index="setting">
      <i class="el-icon-setting"></i>
      <span slot="title">Setting</span>
    </el-menu-item> -->
    <el-submenu index="setting">
      <template slot="title">
        <i class="el-icon-setting"></i>
        <span>Setting</span>
      </template>
      <!-- <el-menu-item index="user">User</el-menu-item> -->
      <el-menu-item index="user">
        <i class="el-icon-user-solid"></i>
        <span slot="title">User</span>
      </el-menu-item>
      <el-menu-item index="customer">
        <i class="el-icon-wodekehu1"></i>
        <span slot="title">Customer</span>
      </el-menu-item>
      <el-menu-item index="SLA">
        <i class="el-icon-zhifuxieyishezhi"></i>
        <span slot="title">SLA</span>
      </el-menu-item>
    </el-submenu>
    <!-- <el-submenu index="tools">
      <template slot="title">
        <i class="el-icon-gongju1"></i>
        <span>Tools</span>
      </template>
      <el-menu-item index="translate">Translate</el-menu-item>
      <el-menu-item index="dictionary">Dictionary</el-menu-item>
      <el-menu-item index="transcode">Transcode</el-menu-item>
    </el-submenu> -->
    <!-- <el-menu-item index="release">
      <i class="el-icon-s-flag"></i>
      <span slot="title">Release</span>
    </el-menu-item>
    <el-menu-item index="guidance">
      <i class="el-icon-document"></i>
      <span slot="title">Guidance</span>
    </el-menu-item> -->
    <el-menu-item index="login">
      <i class="el-icon-switch-button"></i>
      <span slot="title">Logout</span>
    </el-menu-item>
  </el-menu>
</template>
<script>
import bus from "../store/bus";
import http from "../utils/http";
export default {
  name: "Menu",
  components: {},
  computed: {
    defaultActive() {
      return this.$route.path.replace("/", "");
    },
  },
  created() {
    bus.$on("collapse", (collapse) => {
      this.collapse = collapse;
    });
  },
  beforeDestroy() {
    bus.$off("collapse", (collapse) => {
      this.collapse = collapse;
    });
  },
  mounted() {},
  data() {
    return {
      collapse: false,
    };
  },
  methods: {
    handleMenuSelect(index) {
      if (index !== "login") {
        this.$bus.$emit("addTabItem", index);
      }
      if (index == "login") {
        localStorage.removeItem("pstoken");
        http.post("login/logout");
      }
    },
  },
};
</script>
<style scoped></style>
