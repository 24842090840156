import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/HomePage.vue";

// To fix the "Error: Redirected when going from “x“ to “x“ via a navigation guard". added on 2021-12-26

const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("../components/Login"),
  },
  {
    path: "/videos",
    name: "VideoPublic",
    component: () => import("../components/VideoPublic"),
  },
  {
    path: "/",
    name: "HomePage",
    component: Home,
    meta: { title: "Home" },
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "Home",
        component: () => import("../components/Home"),
        meta: { title: "Home" },
      },
      {
        path: "/queryRequest",
        name: "QueryRequest",
        component: () => import("../components/RequestList"),
      },
      {
        path: "/report",
        name: "Report",
        component: () => import("../components/Report"),
      },
      {
        path: "/createRequest",
        name: "CreateRequest",
        component: () => import("../components/CreateRequest"),
        meta: { title: "Create Request" },
      },
      {
        path: "/SLA",
        name: "SLA",
        component: () => import("../components/SLA"),
      },
      {
        path: "/requestStatus",
        name: "RequestStatus",
        component: () => import("../components/RequestStatus"),
      },
      {
        path: "/customer",
        name: "Customer",
        component: () => import("../components/Customer"),
      },
      {
        path: "/user",
        name: "User",
        component: () => import("../components/User"),
      },
      {
        path: "/permission",
        name: "Permission",
        component: () => import("../components/Permission"),
      },
      {
        path: "/request",
        name: "Request",
        component: () => import("../components/Request"),
      },
      {
        path: "/requestType",
        name: "RequestType",
        component: () => import("../components/RequestType"),
      },
      {
        path: "/release",
        name: "Release",
        component: () => import("../components/Release"),
      },
      {
        path: "/transcode",
        name: "Transcode",
        component: () => import("../components/Transcode"),
      },
      {
        path: "/translate",
        name: "Translate",
        component: () => import("../components/Translate"),
      },
      {
        path: "/guidance",
        name: "Guidance",
        component: () => import("../components/Guidance"),
      },
      {
        path: "/POC",
        name: "POC",
        component: () => import("../components/POC"),
      },
      {
        path: "/opportunity",
        name: "Opportunity",
        component: () => import("../components/Opportunity"),
      },
      {
        path: "/dictionary",
        name: "Dictionary",
        component: () => import("../components/Dictionary"),
      },
      {
        path: "/video",
        name: "Video",
        component: () => import("../components/Video"),
      },
    ],
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
