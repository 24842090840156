import axios from "axios";
import qs from "qs";

axios.defaults.timeout = 12000; // 响应时间
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded;charset=UTF-8"; // 配置请求头
axios.defaults.headers.put["Content-Type"] = "application/json;charset=UTF-8"; // 配置请求头

// axios.defaults.baseURL = "http://localhost:3000/"; // 配置接口地址
// axios.defaults.baseURL = "http://52.81.65.129:3000/"; // 配置接口地址
axios.defaults.baseURL = "https://ps.mpstages.cn/api/"; // 配置接口地址

// const requestUploadUrl = "http://localhost:3000/request/file_upload";
// const requestUploadUrl = "http://172.31.23.196:3000/request/file_upload";

var baseURL = axios.defaults.baseURL;

axios.defaults.withCredentials = true;

// POST传参序列化(添加请求拦截器)
axios.interceptors.request.use(
  (config) => {
    // 在header中加上cas_ticket
    let token = localStorage.getItem("pstoken");
    if (token) {
      config.headers["pstoken"] = token;
    }
    // 在发送请求之前做某件事
    if (config.method === "post") {
      config.data = qs.stringify(config.data);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 返回状态判断(添加响应拦截器)
axios.interceptors.response.use(
  (res) => {
    // 对响应数据做些事
    if (res.data.errorCode === "USER_NOT_LOGIN") {
      window.location.href = "/login";
    } else if (res.data.errorCode === "USER_CURRENT_ENTITY_IS_EMPTY") {
      window.location.href = "#/setting/changecompany";
    }
    return res;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 返回一个Promise(发送post请求)
export function post(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      .then(
        (response) => {
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      )
      .catch((error) => {
        reject(error);
      });
  });
}

// 返回一个Promise(发送get请求)
export function get(url, param) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, { params: param })
      .then(
        (response) => {
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      )
      .catch((error) => {
        reject(error);
      });
  });
}
export function put(url, data) {
  return new Promise((resolve, reject) => {
    axios
      .put(url, data)
      .then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      )
      .catch((error) => {
        reject(error);
      });
  });
}
export default {
  post,
  get,
  put,
  baseURL,
};
