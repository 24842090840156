<template>
  <div class="footer">
    <a href="https://beian.miit.gov.cn/" target="_blank"
      >沪ICP备2021011310号-1</a
    >
  </div>
</template>
<script>
export default {
  name: "Footer",
};
</script>
<style scoped>
.footer {
  width: 100%;
  height: 30px;
  line-height: 30px;
  bottom: 0;
  position: fixed;
  background: rgb(236, 236, 234);
  font-size: 12px;
}
</style>
